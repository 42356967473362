import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../Home/v3/styledComponents';

const ProgressBarContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 59%;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0px 130px 0px;
  padding: 0 62px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 80%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 70px;
  }
`;

const Progress = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align center;
`;

const ProgressDot = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background: ${props => props.isActive ? colors.navy : colors.blueGrey};
  border: 1px solid ${colors.lightGrey};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-bottom: 4px;
  @media (max-width: ${mobileThresholdPixels}) {
    height: 15px;
    width: 15px;
  }
`;

const ProgressText = styled.div`
  text-align: center;
  font-family: Gotham;
  line-height: 25px;
  font-size: 15px;
  font-weight: 500;
  color: ${colors.navy};
  position: absolute;
  top: 40px;
  width: 200px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 80px;
    font-size: 10px;
    top: 25px;
  }
`;

const Line = styled.div`
  position: relative;
  top: -3px;
  flex: 1;
  height: 3px;
  width: 100%;
  background-color:  ${props => props.isActive ? colors.navy : colors.blueGrey};
  @media (max-width: ${mobileThresholdPixels}) {
    top: -2px;
  }
`;

const steps = [
  { id: 1, name: 'Besoins' },
  { id: 2, name: 'Disponibilités' },
  { id: 3, name: 'Coordonnées' },
];

const ProgressBar = ({ step }) => (
  <ProgressBarContainer>
    {steps.map((stepIt, index) => {
      const stepId = stepIt.id;
      return (
        <React.Fragment key={stepId}>
          <Progress>
            <ProgressDot isActive={step >= stepId} />
            <ProgressText>{stepIt.name}</ProgressText>
          </Progress>
          {index + 1 !== steps.length && <Line isActive={step >= stepId + 1} />}
        </React.Fragment>
      );
    })}
  </ProgressBarContainer>
);

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ProgressBar;
