import React from 'react';

import Layout from '../../layouts/index';
import InfosContainer from './containers/InfosContainer';
import MenuBar from '../../components/Home/MenuBar';

const Step3 = () => (
  <Layout routeSlug="Step3">
    <MenuBar />
    <InfosContainer />
  </Layout>
);

export default Step3;
